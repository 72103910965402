<template>
  <button class="btn bold" :class="[color, size, {'w-100': width === '100'}]" @click="$emit('buttonClicked')" :disabled="disabled || isLoading">
    <div class="row m-0 align-items-center h-100" :class="[alignment, {'justify-content-center': !alignment}]">
      <div class="col-auto ps-0 pe-1" v-if="icon2">
        <div class="spinner-border spinner-border-sm" role="status" v-if="isLoading">
          <span class="visually-hidden">Loading...</span>
        </div>
        <slot name="iconStart" v-else />
      </div>
      <div class="col ps-0 pe-0" :class="{'ps-1': icon, 'pe-1': icon2}" v-if="btnText">{{btnText}}</div>
      <div class="col-auto pe-0 ps-0" v-if="icon" :class="{'ps-1': btnText }">
        <div class="spinner-border spinner-border-sm" role="status" v-if="isLoading">
          <span class="visually-hidden">Loading...</span>
        </div>
        <slot v-else />
      </div>
      <div class="col-auto pe-0 ps-0" v-if="!icon && !icon2 && isLoading" :class="{'ps-1': btnText }">
        <div class="spinner-border spinner-border-sm" role="status" v-if="isLoading">
          <span class="visually-hidden">Loading...</span>
        </div>
      </div>
    </div>
  </button>
</template>

<script>
export default {
  name: 'Button',
  props: ['color', 'size', 'width', 'btnText', 'icon', 'icon2', 'alignment', 'isLoading', 'disabled']
}
</script>

<style scoped>
  .btn {
    border-radius: 2rem;
    font-size: 15px;
    padding: 0 24px;
    line-height: 15px;
    border: 2px solid transparent;
    height: 39px;
  }
  .btn.font20 {
    font-size: 20px;
  }
  .xxsmall {
    border-radius: 2rem;
    font-size: 0.8rem;
    height: 2rem;
    padding-left: 0.1rem;
    padding-right: 0.1rem;
    width: 2rem;
  }
  .xsmall {
    border-radius: 1.8rem;
    font-size: 0.8rem;
    height: 1.8rem;
    padding-left: 0.5rem;
    padding-right: 0.5rem;
  }
  .small {
    border-radius: 2rem;
    font-size: 0.8rem;
    height: 2rem;
    padding-left: 0.75rem;
    padding-right: 0.75rem;
  }
  .smallish {
    border-radius: 25px;
    font-size: 15px;
    height: 25px;
    padding: 0 11px;
    font-weight: 500;
    font-family: "quicksand_medium";
    color: #1a6836;
  }
  .smallish:hover {
    background-color: rgb(244, 244, 244);
    color: #1a6836;
  }
  .smallmobile {
    border-radius: 2rem;
    font-size: 0.8rem;
    height: 2rem;
    padding-left: 0.75rem;
    padding-right: 0.75rem;
  }
  .menu {
    border-radius: 2rem;
    font-size: 0.8rem;
    padding: 0 1rem;
    line-height: 1;
    border: 2px solid transparent;
    height: calc(2.5rem + 4px);
  }
  .big {
    border-radius: 50px;
    font-size: 19px;
    line-height: 19px;
    padding: 0 23px;
    font-weight: 700;
    font-family: "quicksand_bold";
  }
  .big_menu {
    border-radius: 50px;
    font-size: 19px;
    line-height: 19px;
    padding: 0 23px;
    font-weight: 700;
    font-family: "quicksand_bold";
    height: 45px;
  }
  .red {
    background-color: var(--red-color);
    border: 2px solid var(--red-color);
    color: #fff;
  }
  .red:hover {
    background-color: var(--red-color-light);
    color: #fff;
  }
  .gold {
    background-color: var(--gold-color);
    border: 2px solid var(--gold-color);
    color: #000;
  }
  .gold:hover {
    background-color: var(--gold-color);
    color: #fff;
  }
  .goldGreen {
    background-color: var(--gold-color);
    border: 2px solid var(--gold-color);
    color: var(--green-color);
  }
  .goldGreen:hover {
    background-color: var(--green-color);
    border: 2px solid var(--green-color);
    color: var(--gold-color);
  }
  .green {
    background-color: var(--green-color-dark);
    border: 2px solid var(--green-color-dark);
    color: var(--gold-color);
  }
  .green:hover {
    background-color: var(--green-color-light);
    color: var(--gold-color);
  }
  .green_light {
    background-color: var(--green-color);
    border: 2px solid var(--green-color);
    color: #fff;
  }
  .green_light:hover {
    background-color: var(--green-color-light);
    color: #fff;
  }
  .transparent {
    background-color: transparent;
    border: 2px solid transparent;
    color: inherit;
  }
  .transparent:hover {
    background-color: rgba(224, 224, 224, 1);
  }
  .grey {
    background-color: rgb(249, 250, 251);
    border: 2px solid rgb(249, 250, 251);
    color: inherit;
  }
  .grey:hover {
    background-color: rgb(249, 250, 251);
  }
  .grey_gold {
    background-color: rgb(249, 250, 251);
    border: 2px solid rgb(249, 250, 251);
    color: var(--green-color-dark);
  }
  .grey_gold:hover {
    background-color: var(--gold-color);
    color: var(--green-color-dark);
  }
  .white {
    background-color: #fff;
    border: 2px solid #fff;
    color: var(--green-color-dark);
  }
  .white:hover {
    background-color: rgb(249, 250, 251);
  }
  .darkgrey {
    background-color: rgb(224, 224, 224);
    border: 2px solid rgb(224, 224, 224);
    color: inherit;
  }
  .darkgrey:hover {
    background-color: var(--green-color-light);
    border: 2px solid var(--green-color-light);
    color: #fff;
  }
  .darkgrey_red {
    background-color: rgb(224, 224, 224);
    border: 2px solid rgb(224, 224, 224);
    color: inherit;
  }
  .darkgrey_red:hover {
    background-color: var(--red-color);
    border: 2px solid var(--red-color);
    color: #fff;
  }
  .dark {
    background-color: rgb(7, 36, 18);
    border: 0px solid rgb(224, 224, 224);
  }
  .dark:hover {
    background-color: var(--green-color-light);
    border: 0px solid var(--green-color-light);
    color: #fff;
  }
  .black_op {
    background-color: rgba(0, 0, 0, 0.7);
    border: 0px solid rgba(0, 0, 0, 0.7);
    color: #fff;
  }
  .black_op:hover {
    background-color: rgba(0, 0, 0, 0.7);
    border: 0px solid rgba(0, 0, 0, 0.7);
    color: #fff;
  }
  .outline {
    background-color: transparent;
    color: var(--normal-color);
    border: 1px solid var(--normal-color);
  }
  .outline:hover {
    background-color: transparent;
    border: 1px solid var(--green-color-light);
  }
  .lightgrey_gold {
    background-color:  rgb(244, 244, 244);
    border: 0px;
    color: var(--green-color);
  }
  .lightgrey_gold:hover {
    background-color:  rgb(255, 218, 156);
    border: 0px;
    color: var(--green-color);
  }
  @media (min-width: 992px) {
    .smallmobile {
      border-radius: 2rem;
      font-size: 1rem;
      padding: 0 1rem;
      line-height: 1;
      border: 2px solid transparent;
      height: calc(2.5rem + 4px);
    }
  }
</style>
