<template>
  <Popup @close="$emit('closeDelete')">
    <div class="row mx-0 justify-content-center">
      <div class="col-12 text-center mb-3 green-text bold font19">
        Cancel Recurring Donation {{item.recurringDonationID}}
      </div>
      <div class="col-12 text-center mb-3">
        Are you sure you would like to cancel this recurring donation?
      </div>
      <div class="col-6 px-1 text-center">
        <Button color="grey" btnText="Keep Recurring Donation" width="100" @buttonClicked="$emit('closeDelete')" />
      </div>
      <div class="col-6 px-1 text-center">
        <Button color="red" btnText="Cancel Recurring Donation" width="100" @buttonClicked="cancelRecurring" :isLoading="isLoading" />
      </div>
    </div>
  </Popup>
</template>
<script>
import Popup from '@/views/Popup.vue'
import { mapActions } from 'vuex'
import Button from '@/components/Button.vue'

export default {
  components: {
    Popup,
    Button
  },
  name: 'CancelRecurringDonation',
  props: ['item'],
  emits: ['closeDelete', 'close'],
  data () {
    return {
      isLoading: false
    }
  },
  methods: {
    ...mapActions(['cancelRecurringDonation']),
    async cancelRecurring () {
      this.isLoading = true
      await this.cancelRecurringDonation(this.item.recurringDonationID)
      this.isLoading = false
      this.$emit('close')
    }
  }
}
</script>
